function currency (v, vnd = false) {
  return new Intl.NumberFormat(vnd ? 'vi-VN' : 'jp-JP', { style: 'currency', currency: vnd ? 'VND' : 'JPY' }).format(v)
}
export default {
  vnd: (v) => {
    return currency(v, true)
  },
  jpy: (v) => {
    return currency(v)
  },
}
