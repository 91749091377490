<template>
  <v-container class="my-6">
    <loading
      :show="loading"
      label="Đang tải dữ liệu..."
    />
    <v-row>
      <v-col
        class="align"
        cols="12"
        lg="3"
      >
        <v-carousel>
          <v-carousel-item
            v-for="img in ImageList"
            :key="img[0]"
            :src="img[1]"
            reverse-transition="fade-transition"
            transition="fade-transition"
          />
        </v-carousel>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <h1 class="mb-4">
          {{ item.Title }}
        </h1>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            <v-btn
              color="blue"
              text
              x-small
            >
              <vue-countdown-timer
                :start-time="moment(item.StartTime).unix()"
                :end-time="moment(item.EndTime).unix()"
                :interval="1000"
                end-text="ĐÃ KẾT THÚC"
                day-txt="ngày"
                hour-txt="giờ"
                minutes-txt="phút"
                seconds-txt="giây"
              />
            </v-btn>
          </p>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Seller:
          </p>
          <p class="font-600 mb-0">
            <router-link
              v-if="item.Seller"
              :to="SellerLink(item.Seller.Id,'ya')"
            >
              {{ item.Seller.Id }}
            </router-link>
          </p>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Số lượt đấu giá:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ item.Bids }} <v-icon>mdi-gavel</v-icon>
          </div>
        </div>
        <div class="mb-4">
          <h2 class="primary--text">
            {{ vnd(item.Price*System.TiGia) }}
            ({{ jpy(item.Price) }})
          </h2>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Trạng thái:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ ItemStatus }}
          </div>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Hoàn trả:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ ItemReturn }}
          </div>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Số lượng:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ item.Quantity }}
          </div>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Giá khởi điểm:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ vnd(item.Initprice*System.TiGia) }}
            ({{ jpy(item.Initprice) }})
          </div>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            Auction ID:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            {{ item.AuctionID }}
          </div>
        </div>
        <div class="mb-6">
          <template v-if="item.Status === 'open'">
            <template v-if="!isAuthenticated">
              <br>
              <v-alert
                border="top"
                colored-border
                type="info"
                elevation="2"
              >
                Bạn cần <v-btn
                  small
                  color="primary"
                  @click="ToggleSignIn"
                >
                  Đăng nhập
                </v-btn> để thực hiện việc đấu giá.
              </v-alert>
            </template>
            <template v-else>
              <v-row>
                <v-col
                  cols="3"
                >
                  <v-btn
                    x-large
                    color="primary"
                  >
                    ĐẤU GIÁ
                  </v-btn>
                </v-col>
                <v-col
                  cols="3"
                >
                  <v-btn
                    x-large
                    color="primary"
                  >
                    ĐẤU GIÁ TỰ ĐỘNG
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-else>
            <p>
              <v-alert
                border="top"
                colored-border
                type="error"
                elevation="1"
              >
                ĐẤU GIÁ ĐÃ KẾT THÚC
              </v-alert>
            </p>
          </template>
        </div>
      </v-col>
      <v-col
        class="align"
        cols="12"
        lg="3"
      >
        <div class="d-flex mb-4">
          <p class="font-600 mb-0">
            <v-btn
              outlined
              color="primary"
            >
              Xem tại Y! Auction
            </v-btn>
          </p>
        </div>
        <v-card>
          <v-card-title>Thông tin người bán</v-card-title>
          <v-card-subtitle>
            <p>
              <v-btn
                text
                color="primary"
              >
                {{ item.Seller.Id }}
              </v-btn>
            </p>
            <p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="orange"
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.Seller.Rating.TotalGoodRating }} <v-icon
                      color="orange"
                      small
                    >
                      mdi-thumb-up
                    </v-icon>
                  </v-btn>
                </template>
                <span>Đánh giá tốt</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.Seller.Rating.TotalNormalRating }} <v-icon
                      color="primary"
                      small
                    >
                      mdi-thumbs-up-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>Đánh giá trung bình</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.Seller.Rating.TotalBadRating }} <v-icon
                      color="error"
                      small
                    >
                      mdi-thumb-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>Đánh giá xấu</span>
              </v-tooltip>
              <v-btn
                v-if="SellRate.rate > 0"
                :color="SellRate.color"
                small
                text
              >
                {{ SellRate.rate }}%
              </v-btn>
            </p>
            <p v-if="item.Seller.Rating.Point < 100">
              <v-alert
                border="top"
                colored-border
                type="error"
                elevation="1"
              >
                <strong>Cảnh báo!</strong>. Seller này có lượt đánh giá rất thấp. Hãy cẩn thận !!!
              </v-alert>
            </p>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="mt-12"
      >
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="primary"
          class=""
        >
          <v-tab
            class="font-600 text-capitalize"
            href="#tab-ttsp"
          >
            Giới thiệu
          </v-tab>
          <v-tab
            class="font-600 text-capitalize"
            href="#tab-lsgd"
          >
            Lịch sử đấu giá
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="transparent my-6 pb-6"
        >
          <v-tab-item value="tab-ttsp">
            <v-responsive
              class="overflow-y-auto"
              max-height="600"
            >
              <v-btn
                dark
                color="blue"
                @click="doTranslation"
              >
                Dịch
              </v-btn>
              <br>
              <div
                id="google_translate_element"
                v-html="itemdesc"
              />
              <v-dialog
                v-model="translateDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <v-card
                  width="100%"
                  height="100%"
                >
                  <v-toolbar
                    dark
                    color="primary"
                  >
                    <v-btn
                      icon
                      dark
                      @click="translateDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <iframe
                    id="ifrm"
                    src="/translate"
                    width="100%"
                    height="100%"
                  />
                </v-card>
              </v-dialog>
            </v-responsive>
          </v-tab-item>
          <v-tab-item value="tab-lsgd">
            <v-container
              v-if="load_history"
              style="height: 200px;"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Đang tải lịch sử đấu giá
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="blue accent-4"
                    indeterminate
                    rounded
                    height="6"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-alert
              v-if="history.length === 0 && load_history === false"
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              Sản phẩm này hiện chưa có lịch sử đấu giá. Hãy thử lại sau !!!
            </v-alert>
            <div v-else>
              <br>
              <v-responsive
                class="overflow-y-auto"
                max-height="400"
              >
                <p
                  v-for="(data,index) in history"
                  :key="index"
                >
                  {{ data.Date }} - {{ data.Bid }} - {{ data.Auto ? "Tự động đặt giá":"Đặt giá" }} - {{ jpy(data.Amount) }} - {{ vnd(data.Amount*System.TiGia) }}
                </p>
              </v-responsive>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <h3 class="mb-6">
          Related Products
        </h3>

        <v-row>
          <!-- <v-col
            v-for="(n, index) in singleProducts"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <card-cart :content-img="n.img" />
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import api from '@/plugins/helpers/api'
  import { mapGetters, mapActions } from 'vuex'
  import { Base64 } from 'js-base64'
  import LinkHelpers from '@/plugins/helpers/link'
  import loading from 'vue-full-loading'
  import CurrencyHelper from '@/plugins/helpers/currency'
  // import CardCart from '@/components/cartCard/CardCart'
  export default {
    components: {
      // CardCart,
      loading,
    },
    data: function () {
      return {
        translateDialog: false,
        loading: false,
        tab: null,
        item: {
          Seller: {
            Rating: {},
          },
          Img: {},
          ItemStatus: {
            Condition: 'used',
          },
          ItemReturnable: {},
        },
        itemdesc: null,
        load_history: true,
        history: [],
        languages: [{
          code: 'vi',
          name: 'Tiếng Việt',
          cname: '越南语',
          ename: 'Vietnamese',
        }],
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'System']),
      ImageList () {
        const Img = this.item.Img
        if (Img === null || Img === undefined) return []
        return Object.entries(Img)
      },
      ItemStatus () {
        const Status = this.item.ItemStatus.Condition
        if (Status.includes('used') !== -1) {
          return 'Đã qua sử dụng'
        }
        return 'Mới'
      },
      ItemReturn () {
        const Status = this.item.ItemReturnable.Allowed
        if (Status === true) {
          return 'Có'
        }
        return 'Không'
      },
      SellRate () {
        const good = parseInt(this.item.Seller.Rating.TotalGoodRating)
        const normal = parseInt(this.item.Seller.Rating.TotalNormalRating)
        const bad = parseInt(this.item.Seller.Rating.TotalBadRating)
        const total = good + normal + bad
        const percent = Math.floor(good * 100 / total)
        const color = (percent >= 50) ? 'success' : 'error'
        return { rate: Math.floor(good * 100 / total), color: color }
      },
      ChoPhepDauGia () {
        const dongbang = this.$store.state.user.dongbang
        if (!this.isAuthenticated) return false
        if (dongbang < 2000000) return false
        if (this.$store.state.user.sodiachi.length <= 0) return false
        return true
      },
      DaDatCoc () {
        const dongbang = this.$store.state.user.dongbang
        if (dongbang < 2000000) return false
        return true
      },
      DaCoDiaChi () {
        if (this.$store.state.user.sodiachi.length <= 0) return false
        return true
      },
    },
    watch: {
      tab: function (n, o) {
        if (n !== o && n === 'tab-lsgd') {
          this.LichSuDauGia()
        }
      },
    },
    mounted: function () {
      this.LayDuLieu()
    },
    methods: {
      ...CurrencyHelper,
      ...LinkHelpers,
      ...mapActions(['ToggleSignIn']),
      doTranslation () {
        localStorage.setItem('content', this.itemdesc)
        this.translateDialog = true
      },
      LayDuLieu () {
        fetch(`${api.URL}/ya/item/${this.$route.params.auctionid}`).then(resp => {
          if (resp.status !== 200) {
            return Promise.reject(resp.status)
          }
          return resp.json()
        })
          .then(json => {
            this.item = json.data
            this.itemdesc = Base64.decode(json.data.Description)
          })
          .catch(err => {
            this.$toast.error(err, { position: 'top-right' })
          })
      },
      LichSuDauGia () {
        fetch(`${api.URL}/ya/item/history/${this.$route.params.auctionid}`).then(resp => {
          if (resp.status !== 200) {
            return Promise.reject(resp.status)
          }
          return resp.json()
        })
          .then(json => {
            this.history = json.data
          })
          .catch(err => {
            this.$toast.error(err, { position: 'top-right' })
          })
          .finally(() => {
            this.load_history = false
          })
      },
    },
  }
</script>
